html,body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

@font-face {
  font-family: 'StickNoBills';
  src: url('fnt/StickNoBills.ttf');
}

@font-face {
  font-family: 'LED';
  src: url('fnt/LED.ttf');
}

@font-face {
  font-family: 'NTR';
  src: url('fnt/NTR.ttf');
}
